/**
* Generated automatically at built-time (2024-08-23T13:50:21.191Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkuechenkollektionen-ueberblick",templateKey: "sites/105-fdc23d2f-230d-49cc-99ff-f26e9e722c03"};