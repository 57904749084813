/**
* Generated automatically at built-time (2024-08-23T13:50:21.232Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-outdoorkueche",templateKey: "sites/105-f363ba51-92ef-42f1-ae8a-cb615990d549"};